import { useNavigate } from 'react-router-dom'

// assets
import backBtnIcon from '../../../assets/icons/back-btn-icon.png'

// styles
import './SecondaryBtn.scss'

export default function SecondaryBtn({ label, route=-1 }) {
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(route)} className='secondary-btn-container'>
            <img src={backBtnIcon} alt='back button icon' />
            <h4>{ label }</h4>
        </div>
    )
}