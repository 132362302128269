import styled from "styled-components";

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

    .logo-container {
        cursor: pointer;
        
        img {
            height: 42px;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 50px 0px;

        .logo-container {
            img {
                height: 84px;
            }
        }
    }
`