import { useNavigate } from 'react-router-dom'

// styles
import './GlassPanel.scss'

export default function GlassPanel({ children, clickNav=null, clickNavExternal=null }) {
    const navigate = useNavigate()

    const handleClick = () => {
        if (clickNav) {
            navigate(clickNav)
        }
    }

    return (
        <div onClick={() => handleClick()} className='glass-panel-container'>
            { children }
        </div>
    )
}