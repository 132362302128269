// assets
import bg from '../../assets/bg.png'

// styles
import './Frame.scss'

export default function Frame({ children, bgImg=bg }) {
  return (
    <div className='frame-container' style={{ backgroundImage: `url(${bgImg})` }}>
        { children }
    </div>
  )
}