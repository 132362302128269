import { useNavigate } from 'react-router-dom'

// styles
import { HeaderContainer } from './Header.styles';

// assets
import platformLogoLight from '../../assets/platform-logo-light.png'

// components
import SecondaryBtn from '../btns/secondary-btn/SecondaryBtn'

export default function Header({ backBtn = false }) {
    const navigate = useNavigate()

    return (
        <HeaderContainer>
            <div className='logo-container' onClick={() => navigate('/')}>
                <img src={platformLogoLight} alt='Platform logo light' />
            </div>
            {backBtn &&
                <SecondaryBtn label={'BACK'} />
            }
        </HeaderContainer>
    )
}