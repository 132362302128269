import styled from "styled-components";

export const FooterContainer = styled.div`
    text-align: center;

    .clients-scroll-row-header {
        text-align: center;

        h3 {
            padding-top: 40px;
            padding-bottom: 10px;
            text-shadow: 4px 4px 20px #505050;
        }
    }

    .clients-scroll-row {
        overflow: hidden;
        padding: 30px 0;
        white-space: nowrap;

        .clients-scroll-row-images {
            display: inline-block;
            animation: 30s slide infinite linear;

            img {
                margin: 0 40px;
            }
        }
    }

    .clients-scroll-row:hover {
        .clients-scroll-row-images {
            animation-play-state: paused;
        }
    }

    @keyframes slide {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
    }
    @media screen and (max-width: 367px) {
        .clients-scroll-row {
            .clients-scroll-row-images {
                img {
                    height: 30px;
                }
            }
        }
    }
    @media screen and (min-width: 367px) and (max-width: 397px) {
        .clients-scroll-row {
            .clients-scroll-row-images {
                img {
                    height: 40px;
                }
            }
        }
    }
    @media screen and (min-width: 398px) {
        .clients-scroll-row {
            .clients-scroll-row-images {
                img {
                    height: 50px;
                }
            }
        }
    }

    #footer-platform-logo {
        height: 30px;
        padding: 30px;
    }
`