// assets
import alwaysActiveServicesLogo from './client-logos/always-active-services-logo.png';
import hashcashLogo from './client-logos/hashcash-logo.png';
import seattleCentralLogo from './client-logos/seattle-central-college-logo.png';
import twoAndAHalfHappyBarbersLogo from './client-logos/two-and-a-half-happy-barbers-logo.png';

// styles
import { FooterContainer } from './Footer.styles.js';

export default function Footer({ clients = true }) {
  return (
    <FooterContainer>
      {clients &&
        <>
          <div className='clients-scroll-row-header'>
            <h3>CLIENTS</h3>
          </div>
          <div className='clients-scroll-row'>
            <div className='clients-scroll-row-images'>
              <img src={twoAndAHalfHappyBarbersLogo} alt='2 and a Half Happy Barbers logo' />
              <img src={hashcashLogo} alt='HashCa$h logo' />
              <img src={seattleCentralLogo} alt='Seattle Central College logo' />
              <img src={alwaysActiveServicesLogo} alt='Always Active Services logo' />
              <img src={twoAndAHalfHappyBarbersLogo} alt='2 and a Half Happy Barbers logo' />
              <img src={hashcashLogo} alt='HashCa$h logo' />
              <img src={seattleCentralLogo} alt='Seattle Central College logo' />
              <img src={alwaysActiveServicesLogo} alt='Always Active Services logo' />
            </div>
            <div className='clients-scroll-row-images'>
              <img src={twoAndAHalfHappyBarbersLogo} alt='2 and a Half Happy Barbers logo' />
              <img src={hashcashLogo} alt='HashCa$h logo' />
              <img src={seattleCentralLogo} alt='Seattle Central College logo' />
              <img src={alwaysActiveServicesLogo} alt='Always Active Services logo' />
              <img src={twoAndAHalfHappyBarbersLogo} alt='2 and a Half Happy Barbers logo' />
              <img src={hashcashLogo} alt='HashCa$h logo' />
              <img src={seattleCentralLogo} alt='Seattle Central College logo' />
              <img src={alwaysActiveServicesLogo} alt='Always Active Services logo' />
            </div>
          </div>
        </>
      }
    </FooterContainer>
  )
}