import { useEffect } from 'react';
import { BrowserRouter, useLocation, Route, Routes } from 'react-router-dom';

// styles
import './App.css';

// pages
import About from './pages/about/About'
import Experiences from './pages/experiences/Experiences';
import EventExperiences from './pages/experiences/event-experiences/EventExperiences'
import Home from './pages/home/Home';
import MiscExperiences from './pages/experiences/misc-experiences/MiscExperiences';
import ProductExperiences from './pages/experiences/product-experiences/ProductExperiences';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/experiences' element={<Experiences />} />
          <Route path='/product-experiences' element={<ProductExperiences title='PRODUCT EXPERIENCES'/>} />
          <Route path='/event-experiences' element={<EventExperiences title='EVENT EXPERIENCES'/>} />
          <Route path='/misc-experiences' element={<MiscExperiences title='MISC. EXPERIENCES'/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
