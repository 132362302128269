// styles
import './MiscExperiences.scss'

// components
import Footer from '../../../components/footer/Footer'
import Frame from '../../frame/Frame'
import Header from '../../../components/header/Header'

export default function MiscExperiences() {

    return (
        <Frame>
            <div className='misc-experiences-container'>
                <Header backBtn={true} />
                <div className='page-header-container'>

                    <div className='title'>
                        <h1>MISC<br />EXPERIENCES</h1>
                    </div>
                    
                    <div className='description'>
                        <p>
                            Have an idea for a specific experience or marketing campaign in mind? We'd love to work
                            with you to develop any ideas -- shoot us a message for a free consultation! 
                        </p>
                    </div>

                </div>
                <div className='videos-container'>
                    <iframe src="https://www.youtube.com/embed/YrE5_cMaBAo?&amp;start=0&loop=1&autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
            </div>
            <Footer />
        </Frame>
    )
}
