// styles
import './About.scss'

// assets
import aboutBG from './assets/bg-about.png'

// components
import Frame from '../frame/Frame'
import Header from '../../components/header/Header'

export default function About() {
  return (
    <Frame bgImg={aboutBG}>
        <Header backBtn={true} />
        <div className='about-container'>
          <div className='about-content'>
            <div className='text-panel-container'>
              <div className='text-panel'>
                <h2>
                  Plat•form
                </h2>
                <h3>
                  proper noun
                </h3>
                <p>
                  <b>Platform</b> is an experience design agency specializing in creative marketing strategies
                  designed to bring customers closer to businesses through engaging, enjoyable, and
                  inspiring experiences.
                </p>
              </div>
              <div className='text-panel'>
                <h2>
                  plat•form
                </h2>
                <h3>
                  verb
                </h3>
                <p>
                  Our experiences <b>platform</b> businesses in regards to engagement and awareness through
                  mediums of storytelling. If you have a story, we'll help you share it in innovative, impactful
                  and memorable ways.
                </p>
              </div>
            </div>
          </div>
        </div>
    </Frame>
  )
}