// styles
import './EventExperiences.scss'

// components
import Footer from '../../../components/footer/Footer'
import Frame from '../../frame/Frame'
import Header from '../../../components/header/Header'

export default function EventExperiences() {
    return (
        <Frame>
            <div className='event-experiences-container'>
                <Header backBtn={true} />
                <div className='page-header-container'>

                    <div className='title'>
                        <h1>EVENT<br />EXPERIENCES</h1>
                    </div>
                    
                    <div className='description'>
                        <p>
                            Event Experiences are designed to bring people closer to what's going on around them.
                            Augmenting real-world events, these experiences provide additional information, interaction
                            and engagement.
                        </p>
                    </div>

                </div>
                <div className='videos-container'>
                    <iframe src="https://www.youtube.com/embed/POX1D3Lgbic?&amp;start=0&loop=1&autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
            </div>
            <Footer />
        </Frame>
    )
}