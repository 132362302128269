// styles
import './ProductExperiences.scss'

// components
import Footer from '../../../components/footer/Footer'
import Frame from '../../frame/Frame'
import Header from '../../../components/header/Header'

export default function ProductExperiences() {

    return (
        <Frame>
            <div className='product-experiences-container'>
                <Header backBtn={true} />

                <div className='page-header-container'>

                    <div className='title'>
                        <h1>PRODUCT<br />EXPERIENCES</h1>
                    </div>
                    
                    <div className='description'>
                        <p>
                            Step into the future with our Product Experience social media filters.
                            Designed to innovate customer interaction with your products, these AR filters
                            transport you into an immersive, interactive world where your products come to life.
                        </p>
                    </div>

                </div>
                <div className='videos-container'>
                    <iframe src="https://www.youtube.com/embed/cMm6vW1Dg_s?&amp;start=0&loop=1&autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                    <iframe src="https://www.youtube.com/embed/HpweVup5Tuc?&amp;start=0&loop=1&autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                    <iframe src="https://www.youtube.com/embed/J_08l58KaSA?&amp;start=0&loop=1&autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
            </div>
            <Footer />
        </Frame>
    )
}