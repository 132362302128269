// styles
import './FrameRow.scss'

export default function FrameRow({ children }) {
  return (
    <div className='frame-row-container'>
        { children }
    </div>
  )
}
