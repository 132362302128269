// components
import Footer from '../../components/footer/Footer'
import Frame from '../frame/Frame'
import FrameRow from '../frame/components/frame-row/FrameRow'
import GlassPanel from '../../components/widgets/glass-panel/GlassPanel'
import Header from '../../components/header/Header'

export default function Home() {
  return (
    <Frame>
      <Header />
      <FrameRow>     
        <GlassPanel clickNav={'/experiences'}>
          <h2>
            EXPLORE<br/>EXPERIENCES
          </h2>
        </GlassPanel>
        <GlassPanel clickNav={'/about'}>
          <h2>
            WHAT IS<br/>PLATFORM
          </h2>
        </GlassPanel>
        <GlassPanel>
          <a href='https://drive.google.com/drive/folders/1e0F0WPP_jqqTFMqVgUJZWWdsZJS4IbLy?usp=drive_link' target='_blank' rel='noreferrer'> 
            <h2>
              INSTAGRAM & TIKTOK<br/>SAFE AREAS
            </h2>
          </a>
        </GlassPanel> 
      </FrameRow>
      <Footer />
    </Frame>
  )
}