// components
import Footer from '../../components/footer/Footer'
import Frame from '../frame/Frame'
import FrameRow from '../frame/components/frame-row/FrameRow'
import GlassPanel from '../../components/widgets/glass-panel/GlassPanel'
import Header from '../../components/header/Header'

export default function Experiences() {
    return (
        <Frame>
            <Header backBtn={true}/>
            <FrameRow>
                <GlassPanel clickNav={'/product-experiences'}>
                    <h2>PRODUCT EXPERIENCES</h2>
                </GlassPanel>
                <GlassPanel clickNav={'/event-experiences'}>
                    <h2>EVENT EXPERIENCES</h2>
                </GlassPanel>
                <GlassPanel clickNav={'/misc-experiences'}>
                    <h2>MISCELLANEOUS EXPERIENCES</h2>
                </GlassPanel>
            </FrameRow>
            <Footer />
        </Frame>
    )
}